<template lang="pug">
  v-container
    v-row
      v-col(cols="4")
        create-stock(:edit.sync="stockCategory")
      v-col(cols="8")
        table-stock(
          v-model="stockCategory"
          :unit.sync="stockCategoryUnit"
        )
    stock-category-unit(
      v-model="stockCategoryUnit"
    )
</template>
<script>
export default {
  name: 'StockCategory',
  components: {
    CreateStock: () => import('./Create'),
    TableStock: () => import('./Table'),
    stockCategoryUnit: () => import('./StockCategoryUnit'),
  },
  data () {
    return {
      stockCategory: {},
      stockCategoryUnit: {},
    }
  },
}
</script>
